export const seconds2TimeString = (seconds) => {
    if (!seconds) return "-"
    
    const hours = Math.floor(seconds / 3600)

    let remainSeconds = seconds % 3600
    const minutes = Math.floor(remainSeconds / 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    const secondsStr = Math.floor(remainSeconds % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    let timeString = '';
    if (hours)
      timeString += hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      }) + ':'
    else 
      timeString += '00:'

    timeString += minutes + ':' + secondsStr
    return timeString
}