
export const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
    if (cellValue == null) {
        return 0;
    }

    const cellDate = new Date(Date.parse(cellValue))
    cellDate.setHours(0,0,0,0);
 
    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    }
    return 0;
}