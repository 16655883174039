<template>
<div class="d-grid gap-2">
    <button class="btn btn-outline-info mb-1"
            @click="onClick" :disabled="disabled">
    <i class="fa-solid fa-circle-info"></i> Details</button>
</div>
</template>

<script>
import useTableButton from '../composables/useTableButton';
export default {
    setup(props) {
        const {
            disabled,

            onClick
        } = useTableButton(props)
        
        return {
            disabled,

            onClick
        }
    }
}
</script>