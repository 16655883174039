const padTo2Digits = (num)  => {
    return num.toString().padStart(2, '0');
}

const spanishDateFormat = (date) => {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');
}

export const timeRenderer = (datetime) => {
    if (datetime.value)
        return new Date(Date.parse(datetime.value)).toLocaleTimeString()
    else
        return '-'
}

export const dateRenderer = (datetime) => {
    if (datetime.value)
        return spanishDateFormat(new Date(Date.parse(datetime.value)))
    else
        return '-'
}
