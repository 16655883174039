<template>
    <span v-if="value==0" class="badge bg-secondary">Stand by</span>
    <span v-else-if="value==1" class="badge bg-primary">In progress</span>
    <span v-else-if="value==2" class="badge bg-danger">Stopped</span>
    <span v-else-if="value==3" class="badge bg-success">Finished</span>
</template>


<script>
import useTableButton from '../../shared/composables/useTableButton';
export default {
    setup(props) {
        const {
            disabled,

            onClick
        } = useTableButton(props)
        
        return {
            disabled,
            
            onClick,
            value: props.params.value
        }
    }
}
</script>