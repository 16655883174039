<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Work orders dashboard > Finished</h1>
    <hr>
    <div class="row mb-2">
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <!-- <strong class="d-inline-block mb-2 text-success">Design</strong> -->
                    <h5 class="mb-2 text-primary">Sum Quantity</h5>
                    <!-- <div class="mb-1 text-muted">Nov 12</div> -->
                    <strong class="card-text mb-auto">{{ metrics.sumQuantity }}</strong>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <!-- <strong class="d-inline-block mb-2 text-success">Design</strong> -->
                    <h5 class="mb-2 text-primary">Total Orders</h5>
                    <!-- <div class="mb-1 text-muted">Nov 12</div> -->
                    <strong class="card-text mb-auto">{{ metrics.totalOrders }}</strong>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <!-- <strong class="d-inline-block mb-2 text-success">Design</strong> -->
                    <h5 class="mb-2 text-primary">Mean time/item</h5>
                    <!-- <div class="mb-1 text-muted">Nov 12</div> -->
                    <strong class="card-text mb-auto">{{ metrics.meanTimeItem }}</strong>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static bg-card">
                    <!-- <strong class="d-inline-block mb-2 text-success">Design</strong> -->
                    <h5 class="mb-2 text-primary">Sum registry time</h5>
                    <!-- <div class="mb-1 text-muted">Nov 12</div> -->
                    <strong class="card-text mb-auto">{{ metrics.sumRegistryTime }}</strong>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <button class="btn btn-secondary dropdown-toggle"                     
                    id="btn-dropdown-export-table"
                    data-bs-toggle="dropdown">
                Export
            </button>
            <ul class="dropdown-menu" aria-labelledby="btn-dropdown-export-table">
                <li><button class="dropdown-item" @click="onBtnExport()"><i class="fa-solid fa-file-csv"></i> CSV</button></li>
            </ul>
        </div>
    </div>
    <div class="grid-wrapper">
        <ag-grid-vue
            class="backoffice-grid ag-theme-alpine"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :frameworkComponents="frameworkComponents"
            :paginationAutoPageSize="true"
            :pagination="true"
            @grid-ready="onGridReady"
            @model-updated="onGridModelUpdated"
            :rowData="rowData">
        </ag-grid-vue>
    </div>

    <div class="row mt-2">
        <div class="col">
            <button @click="routeBack" class="btn btn-outline-secondary float-end">
                <i class="bi bi-arrow-return-left"></i> Go back
            </button>
        </div>
    </div>
</div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-alpine.css"
import { AgGridVue } from "ag-grid-vue3"
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import { dateRenderer } from '../../shared/helpers/GridRenderers'
import { seconds2TimeString } from '../../shared/helpers/dateTimeHelpers'
import {defaultButtonColumnDef, useGrid} from '../../shared/composables/useGrid'
import TableButtonInfo from '../../shared/components/TableButtonInfo'
import { dateComparator } from '../../shared/helpers/GridComparators'

import { getFinishedOrders } from '../helpers/endpoints';
import CellGridOrderStatus from '../components/CellGridOrderStatus'


const timeSpendRenderer = (timeSpend) => {
    if (timeSpend.value)
        return seconds2TimeString(timeSpend.value)
    else
        return '-'
}

export default {
    components: { AgGridVue },
    setup() {
        const router = useRouter()
        const renderTable = (start_date, end_date) => {
            getFinishedOrders(start_date, end_date).then((response) => {
                rowData.value = response;
            })
        }

        const { 
            defaultColDef, 
            rowData,
            onGridReady,
            exportAsCSV
        } = useGrid(renderTable)

        const columnDefs = reactive([
                { headerName: "Code", field: "code", filter: 'agTextColumnFilter' },
                { headerName: "Product", field: "product", filter: 'agTextColumnFilter' },
                { headerName: "Quantity", field: "quantity", filter: 'agNumberColumnFilter' },
                { 
                    headerName: "Start date", 
                    field: "start_date",
                    filter: 'agDateColumnFilter',
                    cellRenderer: dateRenderer ,
                    filterParams: {
                        // provide comparator function
                        comparator: dateComparator
                    } 
                },
                { 
                    headerName: "Finish date", 
                    field: "finish_date",
                    filter: 'agDateColumnFilter',
                    cellRenderer: dateRenderer ,
                    filterParams: {
                        // provide comparator function
                        comparator: dateComparator
                    } 
                },
                { 
                    headerName: "Status", 
                    field: "status",
                    cellRenderer: 'statusRenderer'
                },
                {
                    headerName: 'Registry time (HH:mm:ss)',
                    field: 'time',
                    cellRenderer: timeSpendRenderer
                },
                {
                    headerName: 'time/item (HH:mm:ss)',
                    field: 'time',
                    valueGetter: (params) => {
                        return seconds2TimeString(params.data['time'] / params.data['quantity'])
                     },
                },
                {
                    field: 'id',
                    cellRenderer: 'btnInfoRenderer',
                    cellRendererParams: {
                        action: (data) => {
                            console.log(data.id);
                            router.push({
                                name: 'backoffice-dashboard-order-details',
                                params: {
                                    id: data.id
                                },
                            })
                        }
                    },
                    ...defaultButtonColumnDef
                }
            ])
        
        const metrics = reactive({
            'sumQuantity': 0,
            'totalOrders': 0,
            'meanTimeItem': 0,
            'sumRegistryTime': 0
        })

        return {
            columnDefs,
            defaultColDef,
            frameworkComponents: reactive({
                btnInfoRenderer: TableButtonInfo,
                statusRenderer: CellGridOrderStatus
            }),
            rowData,
            onGridReady,
            onBtnExport: () => exportAsCSV(),

            metrics,

            onGridModelUpdated: (params) => {
                console.log(params);
                const {api, keepRenderedRows} = params
                if (keepRenderedRows) {
                    console.log("Model Updated -> same rows");
                } else {
                    console.log("Model Update -> new rows");
                }

                const rowModel = api.getModel()
                metrics['sumQuantity'] = 0
                metrics['totalOrders'] = 0
                metrics['meanTimeItem'] = 0
                metrics['sumRegistryTime'] = 0
                rowModel.forEachNodeAfterFilter((rowNode) => {
                    metrics['sumQuantity'] += rowNode.data.quantity
                    metrics['totalOrders'] ++
                    metrics['sumRegistryTime'] += rowNode.data.time
                });

                metrics['meanTimeItem'] = seconds2TimeString(metrics['sumRegistryTime'] / metrics['sumQuantity'])
                metrics['sumRegistryTime'] = seconds2TimeString(metrics['sumRegistryTime'])
            },

            routeBack: () => router.back()
        }
    }
}
</script>
