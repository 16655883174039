import { ref } from "vue"

export const defaultButtonColumnDef = {
    headerName: '',
    flex: 0,
    width:125,
    sortable: false
}

export const useGrid = (renderTable) => {
    const gridApi = ref(null)
    const gridColumnApi = ref(null)
    const defaultColDef = ref({
        flex: 1,
        resizable: true,
        floatingFilter: true,
        sortable: true
    })


    const rowData = ref([])


    return {
        gridApi,
        defaultColDef,
        rowData,
        defaultButtonColumnDef,
        
        exportAsCSV(columnKeys = null) {
            console.log("Export to CSV")
            gridApi.value.exportDataAsCsv({
                columnSeparator: ',',
                columnKeys
            });
        },
    
        onGridReady(params) {
            gridApi.value = params.api
            gridColumnApi.value = params.columnApi
    
            renderTable()
        }
    }
}