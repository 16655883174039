import { computed } from 'vue'

const useTableButton = (props) => {
        
    const onClick = () => {
        if (props.params.action)
            props.params.action(props.params.data)
    }

    const disabled = computed(() => {
        if (!props.params.disabled) {
            return false
        }
        return props.params.disabled(props.params.data)
    })

    return {
        disabled,

        onClick
    }
}

export default useTableButton